import React from 'react'

export const SVGDot = () => (
    <svg width={8} height={8} viewBox="-1 -1 10 10">
        <g>
            <rect
                x="0"
                y="0"
                width="8"
                height="8"
                rx="2"
                transform="rotate(-45 4 4)"
                fill="currentColor"
            />
        </g>
    </svg>
)
